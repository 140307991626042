import React from "react";
import { Helmet } from "react-helmet";
import { faviconDefault } from "../../constants";

import { Consumer as MetaConsumer } from "./context";

const Consumer = ({ children }) => (
  <MetaConsumer>
    {({ title, description, image, type, url, custom, links, favicon }) => {
      return (
        <>
          <Helmet
            title={title}
            meta={[
              { name: "description", content: description },
              { property: "og:url", content: url },
              { property: "og:title", content: title },
              { property: "og:description", content: description },
              { property: "og:type", content: type },
              { property: "og:image", content: image },
              ...custom,
            ]}
            link={[
              { rel: "shortcut icon", href: favicon || faviconDefault },
              ...(links || [])
            ]}
          />
          {children}
        </>
      );
    }}
  </MetaConsumer>
);

export default Consumer;
