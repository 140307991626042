import { websiteDomain } from "../../constants";
const titleDefaultKey = "seo_default_title"
const descriptionDefaultKey = "seo_default_description"

const replaceIfExists = (value, key, valueReplace) => {
    if (value.includes(key)) {
        return value.replaceAll(key, valueReplace)
    }
    return value
}

export const replaceVariables = (value, data) => {
    value = replaceIfExists(value, "${store_name}", data.store_name || "")
    value = replaceIfExists(value, "${store_full_name}", data.store_full_name || "")
    value = replaceIfExists(value, "${store_legal}", data.store_legal || "")
    value = replaceIfExists(value, "${store_phone}", data.store_phone || "")
    value = replaceIfExists(value, "${store_email}", data.store_email || "")
    value = replaceIfExists(value, "${name}", data.name || "")
    value = replaceIfExists(value, "${price}", data.price || "")
    value = replaceIfExists(value, "${currency}", data.currency || "")
    value = replaceIfExists(value, "&nbsp;", "")
    return value
}

export const prepareSEO = (key, meta, metaDefault) => {
  meta = meta || {}
  metaDefault = metaDefault || {}
  const titleKey = `seo_${key}_title`
  const descriptionKey = `seo_${key}_description`

  let title = meta[titleKey] || metaDefault[titleKey] || metaDefault[titleDefaultKey] || meta[titleDefaultKey] || ""
  title = replaceVariables(title, meta)

  let description = meta[descriptionKey] || metaDefault[descriptionKey] || metaDefault[descriptionDefaultKey] || meta[descriptionDefaultKey] || ""
  description = replaceVariables(description, meta)

  return {title, description, image: meta.banner_image || meta.store_logo, favicon: meta.store_favicon}
};



export const prepareSEOCategory = (key, meta, details) => {
  const titleKey = `seo_${key}_title`
  const descriptionKey = `seo_${key}_description`
  let title = details.seoTitle || meta[titleKey] || details.name || meta[titleDefaultKey] || ""
  title = replaceVariables(title, {...meta, ...details})

  let description = details.seoDescription || meta[descriptionKey] || details.name || meta[descriptionDefaultKey] || ""
  description = replaceVariables(description, {...meta, ...details})

  const url = ((websiteDomain|| "").startsWith("http") ? "" : "https://") + websiteDomain + "/category/" + details.slug
  const image = (details.backgroundImage || {}).url || meta.banner_image || meta.store_logo
  const type = "product.category"

  return {title, description, image, url, type, favicon: meta.store_favicon}
}



export const prepareSEOProduct = (key, meta, details) => {
  const titleKey = `seo_${key}_title`
  const descriptionKey = `seo_${key}_description`
  const price = details.pricing?.priceRange?.start?.gross.amount.toString() || "100"
  const currency = details.pricing?.priceRange?.start?.gross.currency || "рублей"
  let title = details.seoTitle || meta[titleKey] || details.name || meta[titleDefaultKey] || ""
  title = replaceVariables(title, {...meta, ...details, price, currency})

  let description = details.seoDescription || meta[descriptionKey] || details.name || meta[descriptionDefaultKey] || ""
  description = replaceVariables(description, {...meta, ...details, price, currency})

  const url = ((websiteDomain|| "").startsWith("http") ? "" : "https://") +  websiteDomain + "/product/" + details.slug
  const image = details.thumbnail?.url || meta.banner_image || meta.store_logo
  const type = "product.item"
  const custom = [
    {
      content: price,
      property: "product:price:amount",
    },
    {
      content: currency,
      property: "product:price:currency",
    },
    {
      content: details.isAvailable ? "in stock" : "in stock",
      property: "product:isAvailable",
    },
    {
      content: details.category?.name,
      property: "product:category",
    }
  ]


  return {title, description, image, url, type, custom, favicon: meta.store_favicon}
}
