import Link from "next/link";
import React from "react";

import styles from "./scss/index.module.scss";

export const Logo = ({ isSmall, src, isVertical, text, width, height }) => {

  return (
    <Link href="/" prefetch={false}>
      <a
        className={
          styles["logo-box"] + (isVertical ? " " + styles.vertical : "")
        }
      >
        {src && (
          <div className={styles.logo}>
            <img
              src={src}
              alt="Logo"
              style={{
                width: width || (isSmall ? 42 : 55),
                height: height || (isSmall ? 42 : 55)
              }}
            />
          </div>
        )}
        {text ? <span className={styles.text}>{text}</span> : null}
      </a>
    </Link>
  );
};
