import React from "react";
import { MainMenu } from "../MainMenu";
import { Footer } from "../Footer";

export const Layout = ({ meta, children }) => {

  return (
    <>
      <MainMenu storeName={meta.store_name} storeLogo={meta.store_logo} />
      {children}
      <Footer
        storeName={meta.store_name}
        storeLogo={meta.store_logo}
        storeEmail={meta.store_email}
        storePhone={meta.store_phone}
        storeLegal={meta.store_legal}
        linkFacebook={meta.link_facebook}
        linkInstagram={meta.link_instagram}
        footerTitle={meta.footer_title}
        footerDescription={meta.footer_description}
      />
    </>
  );
};
