import Link from "next/link";
import React from "react";
import { generatePath } from "react-router";

import { paths } from "../../paths";


export const NavLink = ({ item, ...props }) => {
  const { name, url, category, collection, page } = item;
  const link = (url) => (
    <Link passHref href={url} prefetch={false}>
      <a {...props}>{name}</a>
    </Link>
  );

  if (url) {
    return (
      <a href={url} {...props}>
        {name}
      </a>
    );
  }
  if (category) {
    return link(generatePath(paths.category, { slug: category.slug }));
  }
  if (collection) {
    return link(generatePath(paths.collection, { slug: collection.slug }));
  }
  if (page) {
    return link(generatePath(paths.page, { slug: page.slug }));
  }

  return <span {...props}>{name}</span>;
};
