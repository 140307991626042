import EditorJSHTML from "editorjs-html";
import React, { useRef, useMemo } from "react";

import styles from "./index.module.scss";

export const RichTextEditorContent = ({ jsonData, asText, defaultValue }) => {
  const editorHtml = useRef(EditorJSHTML());

  const data = useMemo(() => {
    const data = jsonData ? JSON.parse(jsonData) : [];
    return data;
  }, [jsonData]);

  if (asText) {
    return <>{data}</>;
  }
  if (!data || data.length === 0) return null;

  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{
        __html: editorHtml.current.parse(data).join(""),
      }}
    />
  );
};

export const getTextFromRichText = (jsonData) => {
  const data = jsonData ? JSON.parse(jsonData) : {};
  return (((data.blocks || [])[0] || {}).data || {}).text || "";
};

