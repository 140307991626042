import Link from "next/link";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "../../intl";
import { Logo } from "../Logo";
import { Nav } from "./Nav";
import {
  RichTextEditorContent,
} from "../atoms/RichTextEditorContent";
import styles from "./scss/index.module.scss";


export const Footer = ({
  menu,
  storeName,
  storeLogo,
  storeEmail,
  storePhone,
  storeLegal,
  linkFacebook,
  linkInstagram,
  footerTitle,
  footerDescription,
}) => {
  const intl = useIntl();

  const menuList = [
    { name: intl.formatMessage(commonMessages.homePage), link: "/" },
    { name: intl.formatMessage(commonMessages.deliveryPage), link: "/delivery" },
    { name: intl.formatMessage(commonMessages.paymentPage), link: "/payment" },
    { name: intl.formatMessage(commonMessages.contactsPage), link: "/contacts" },
    { name: intl.formatMessage(commonMessages.cartPage), link: "/cart" },
    { name: intl.formatMessage(commonMessages.loginPage), link: "/login" },
    { name: intl.formatMessage(commonMessages.signUpPage), link: "/sign-up" },
  ];


  return (
    <div className={styles.footer} id="footer">
      {footerTitle ? (
        <div className={"" + styles["footer-about"] + " container"}>
          <h4 className={styles["title"]}>{footerTitle}</h4>
          <span className={styles["description"]}>
            <RichTextEditorContent jsonData={footerDescription} />
          </span>
        </div>
      ) : null}

      <div className={"container " + styles["footer-box"]}>
        <div className={styles["nav"]}>
          {menuList.map((v, i) => {
            return (
              <Link key={i} href={v.link} prefetch={false}>
                <a>{v.name}</a>
              </Link>
            );
          })}
          <div className={styles["social-box"]}>
            {linkFacebook ? (
              <a href={linkFacebook} target="_blank">
                <svg width="24px" height="24px" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2m13 2h-2.5A3.5 3.5 0 0 0 12 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 0 1 1-1h2V5z"></path></svg>
              </a>
            ) : null}
            {linkInstagram ? (
              <a href={linkInstagram} target="_blank">
                <svg width="24px" height="24px" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>
              </a>
            ) : null}
          </div>
        </div>
        <div className={styles["store-name"]}>
          {storeLogo || storeName ? (
            <div className={styles["logo"]}>
              <Logo
                width={80}
                height={80}
                text={storeName || "Store"}
                isVertical
                src={storeLogo}
              />
            </div>
          ) : null}
        </div>
        <div className={styles["contacts"]}>
          {storeEmail && (
            <span>
              <a href={"mailto: " + storeEmail}>{storeEmail}</a>
            </span>
          )}
          {storePhone && (
            <span>
              <a href={"tel:" + storePhone}>{storePhone}</a>
            </span>
          )}
          {storeLegal && <span>{storeLegal}</span>}
          <span>
            <Link href="/privacy-policy" prefetch={false}>
              <a className="link"><FormattedMessage {...commonMessages.privacyPolicy}/></a>
            </Link>
          </span>
          <span>
            <Link href="/terms-of-use" prefetch={false}>
              <a className="link"><FormattedMessage {...commonMessages.termsOfUse}/></a>
            </Link>
          </span>
        </div>
      </div>
      <Nav menu={menu} />
      <div className={styles["footer-copyright"] + " container"}>
        <span>© 2021 {storeName}. <FormattedMessage {...commonMessages.rightsReserved}/></span>
      </div>
    </div>
  );
};
