import { getTextFromRichText } from "../atoms/RichTextEditorContent";

export const metaPageQuery = `
page(slug: $pageSlug) {
  title
  slug
  attributes {
    attribute {
      name
      slug
    }
    values {
      name
      value
      richText
      file {
        url
        contentType
      }
    }
  }
}
`;

const richFieldsDict = {
  banner_description: true,
  footer_description: true,
  page_delivery_description: true,
  page_payment_description: true,
  page_contacts_description: true,
  page_cart_description: true,
  page_checkout_description: true,
  page_privacy_policy_description: true,
  page_terms_of_use_description: true,
};

export const prepareMetaPage = (page) => {
  const metaDict = {};
  page?.attributes?.forEach((v) => {
    const code = v.attribute.slug;
    const valueObj = (v.values || [])[0] || {};
    let value = "";
    if (valueObj.richText) {
      if (richFieldsDict[code]) {
        if (valueObj.richText) {
          if (valueObj.richText.includes('"blocks": [], "version"')) {
            valueObj.richText = ""
          }
        }
        value = valueObj.richText;
      } else {
        value = getTextFromRichText(valueObj.richText);
      }
    } else if (valueObj.file) {
      value = valueObj.file.url;
    }
    metaDict[code] = value;
  });
  return metaDict;
};

export const preprocessMetaPage = (page, keyList) => {
  keyList = (keyList || []).concat(["default"]);
  let attributes = page.attributes || [];
  attributes = attributes.filter((v) => {
    const code = v.attribute.slug;
    if (code.startsWith("seo_page") || code.startsWith("page_")) {
      let include = false;
      keyList.forEach((v) => {
        if (code.includes(v)) {
          include = true;
        }
      });
      if (include) {
        return true;
      }
      return false;
    }
    return true;
  });
  page.attributes = attributes;
  return page;
};
