import React from "react";

import useMediaQuery from "../../hooks/useMediaQuery";
import Link from "next/link";
import { Logo } from "../Logo";
import NavMenu from "./NavMenu";

import styles from "./scss/MainMenu.module.scss";

export const MainMenu = ({ storeName, storeLogo }) => {
  const isMobile = useMediaQuery(768);

  return (
    <header>
      <nav id={styles.header}>
        <div className={"container " + styles.container}>
          <div className={styles["main-menu"]}>
            <div className={styles["store-name"]}>
              {storeLogo || storeName ? (
                <div className={styles["logo"]}>
                  <Logo
                    isSmall={isMobile}
                    text={storeName || "Store"}
                    src={storeLogo}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles["left"]}>
              {/*<div className={styles["menu-icon"]}>
                <Menu />
              </div>*/}
            </div>

            <div className={styles["right"]}>
              <NavMenu />
              <div className={styles["login-icon"]}>
                <Link href="/login" prefetch={false}>
                  <a>
                    <svg width="24px" height="24px" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
                  </a>
                </Link>
              </div>
              <div className={styles["cart-icon"]}>
                <Link href="/cart" prefetch={false}>
                  <a>
                      <svg width="24px" height="24px" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path></svg>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
