/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import Link from "next/link";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "../../intl";
import styles from './scss/MainMenu.module.scss'




const NavMenu = props => {
  const intl = useIntl();

  const menuList = [
    { name: intl.formatMessage(commonMessages.homePage), link: "/" },
    { name: intl.formatMessage(commonMessages.deliveryPage), link: "/delivery" },
    { name: intl.formatMessage(commonMessages.paymentPage), link: "/payment" },
    { name: intl.formatMessage(commonMessages.contactsPage), link: "/contacts" },
  ];
  return (
    <ul className={styles["nav-menu"]}>
      {menuList.map((item, i) => {
        return (
          <li key={i} className={styles["nav-menu__item"]}>
            <Link href={item.link} prefetch={false}>
              <a>{item.name}</a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NavMenu;
